import React, { useState, useEffect } from "react"
import {
  WppButton,
  WppModal,
  WppIconClose,
  WppFileUpload,
  WppSelect,
  WppListItem,
  WppLabel
} from "@wppopen/components-library-react"
import { useOs } from "@wppopen/react"
import { useNavigate } from "react-router-dom"
import { Grid } from "@mui/material"
import { useSelector, useDispatch } from "react-redux"
import { serviceURL } from "helper/serviceURL"
import styles from "./FileUpload.module.scss"
import useAxiosInterceptors from "hooks/useAxiosInterceptors"
import { getTemplatesDispatcher } from "containers/piaModule/assessmentList/createAssessment/step2/action"
import { SELECT_TYPES } from "helper/constants"

interface FileUploadProps {
  open: boolean
  onClose: (value: boolean) => void
  uploadTost: (value: boolean) => void
}

const FileUpload = ({ open, onClose, uploadTost }: FileUploadProps) => {
  const navigate = useNavigate()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<Array<{ header: string; message: string }>>([])
  const [selectedTemplate, setSelectedTemplate] = useState<{ id: string }>({ id: "" })
  const { axiosInstance } = useAxiosInterceptors()
  const {
    osApi: { getAccessToken }
  } = useOs()
  const dispatch = useDispatch()
  const rowDetails = useSelector((state: any) => state?.inventoryRowDetailsRed?.data)
  const orgId = useSelector((state: any) => state?.selectedSideNavOrgRed?.data?.id)
  const templateList = useSelector((state: any) => state.caGetTemplateReducer.data)

  const handleFileUploadChange = event => {
    setError([])
    const file = event?.detail?.value[0]
    if (file) {
      setSelectedFile(file)
    }
  }

  const handleUpload = () => {
    setIsLoading(true)
    const apiUrl = serviceURL.pgpBaseAPI + "/api/assessments/offline"
    const formData = new FormData()
    const payload = {
      assessmentTemplateId: selectedTemplate?.id,
      inventoryTypeId: 3,
      inventoryId: rowDetails?.id,
      name: `${rowDetails?.name} | ${rowDetails?.market?.name}`,
      orgId: orgId
    }

    formData.append("data", JSON.stringify(payload))

    if (selectedFile) {
      formData.append("file", selectedFile)
    } else {
      console.error("No file selected")
      setIsLoading(false)
      return
    }

    axiosInstance
      .post(apiUrl, formData, {
        headers: {
          accept: "*/*"
        }
      })
      .then((response) => {
        setIsLoading(false)
        uploadTost(true)
        onClose(false)
        navigate(`/pia/assessment/${response?.data?.id}/${orgId}`)
      })
      .catch(error => {
        setIsLoading(false)
        setError(error?.response?.data?.errors)
      })
  }

  useEffect(() => {
    const headers = {
      accept: "*/*",
      Authorization: "Bearer " + getAccessToken()
    }
    getTemplatesDispatcher && dispatch(getTemplatesDispatcher(headers, SELECT_TYPES[2]))
  }, [])

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value)
  }

  return (
    <WppModal open={open} size="m" onWppModalClose={() => onClose(false)} className={styles.uploadContainer}>
      <div slot="header" className={styles.uploadHeader}>
        <h3 className={styles.assessNameProgressContainer}>
          <span>Upload Assessment File</span>
        </h3>
        <WppIconClose color="black" onClick={() => onClose(false)} className={styles.close} size="m"></WppIconClose>
      </div>
      <div slot="body">
        <Grid container>
          <Grid item sm={6} md={6}>
            <WppLabel
              className={styles.label}
              config={{ text: "Assessment Template" }}
              htmlFor="assessment-template"
              typography="s-strong"
            />
            <WppSelect
              id="assessment-template"
              onWppChange={e => handleTemplateChange(e)}
              placeholder="Select Template"
              value={selectedTemplate}
              withSearch={true}
              size="s"
            >
              {templateList &&
                templateList.map(
                  (
                    item: {
                      name: string
                    },
                    idx: number
                  ) => {
                    return (
                      <WppListItem
                        key={idx}
                        value={item}
                        disabled={item?.id !== "af9aba2b-6130-4619-961a-d457cd40144e"}
                      >
                        <p
                          slot="label"
                          className={item?.id !== "af9aba2b-6130-4619-961a-d457cd40144e" ? styles.disable : ""}
                        >
                          {item.name}
                        </p>
                      </WppListItem>
                    )
                  }
                )}
            </WppSelect>
          </Grid>
        </Grid>
        <WppFileUpload
          onWppChange={handleFileUploadChange}
          acceptConfig={{
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
            "application/vnd.ms-excel": [".xls"]
          }}
          className={styles.uploadFile}
          multiple={false}
          disabled={!selectedTemplate?.id}
        />
        {error?.length > 0 && (
          <ul className={styles.listMain}>
            <h3>Please fix the below error(s)</h3>
            {error?.map(item => {
              return (
                <li className={styles.listChild}>
                  <span className={styles.innerLabel}>{item?.header}</span>
                  <span className={styles.innerLabelValue}>{item?.message}</span>
                </li>
              )
            })}
          </ul>
        )}
      </div>
      <div slot="actions" className={styles.btnClose}>
        <Grid item xs={12} sm={12} md={12} display={"flex"} gap={1} justifyContent={"right"}>
          <WppButton
            className={styles.customBtnSecondary}
            variant={"secondary"}
            loading={isLoading}
            onClick={handleUpload}
            disabled={!selectedFile?.name?.trim().length}
          >
            Upload
          </WppButton>
          <WppButton className={styles.customBtnSecondary} variant={"secondary"} onClick={() => onClose(false)}>
            Cancel
          </WppButton>
        </Grid>
      </div>
    </WppModal>
  )
}

export default FileUpload
